export default function componentStyleOverrides(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          minHeight: '100vh',

          // Dark/Light mode switch
          '&::before': {
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: theme.mode === 'light'
              ? `linear-gradient(to right, ${theme.colors.paper}, ${theme.colors?.primaryLight}, ${theme.colors?.primaryLight},${theme.colors.orangeLight}, ${theme.colors.orangeLight})` // Light mode gradient
              : `linear-gradient(to right, ${theme.colors.primaryDark}, ${theme.colors.primaryMain}, ${theme.colors.orangeMain},${theme.colors.orangeMain})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: theme.mode === 'light' ? 'brightness(0.8) contrast(0.8)' : 'brightness(0.8) contrast(1.0)',
            zIndex: -1,
          },

          '&::after': {
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: theme.mode === 'light'
              ? 'rgba(255, 255, 255, 0.7)' // Lighter glass effect for light mode
              : 'rgba(0, 0, 0, 0.6)',      // Darker glass effect for dark mode
            zIndex: -1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          fontWeight: 500,
          ...(theme.mode === 'dark' && {
            '&.MuiButton-outlined': {
              backgroundColor: 'rgba(0, 0, 0, 0.3)', // Use dark background color
            },
          }),
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none', // Remove default background image
          backgroundColor: theme.mode === 'dark' ? `rgba(50, 50, 50, 0.4)` : `rgba(255, 255, 255, 0.5)`,
          backdropFilter: 'blur(10px)', // Apply blur for the glass effect
          webkitBackdropFilter: 'blur(10px)', // Safari support
          border: '1px solid rgba(255, 255, 255, 0.2)', // Optional: subtle border for light mode
        },
        rounded: {
          borderRadius: `10px`, // Use the same border radius as MuiPaper
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: theme.mode === 'dark'
            ? 'rgba(30, 30, 30, 0.1)' // Glassy semi-transparent background for dark mode
            : 'rgba(255, 255, 255, 0.7)', // Glassy semi-transparent background for light mode
          backdropFilter: 'blur(10px)', // Apply blur for the glass effect
          webkitBackdropFilter: 'blur(10px)', // Safari support
          borderColor: theme.divider, // Keep the same border color from the theme
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '15px', // Border radius consistent with MuiPaper
          boxShadow: theme.mode === 'dark'
            ? '0 4px 20px rgba(0, 0, 0, 0.5)' // Darker shadow for dark mode
            : '0 4px 20px rgba(0, 0, 0, 0.1)', // Softer shadow for light mode
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px'
        },
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            backgroundColor: 'transparent'
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.55)', // Slightly darker on hover when selected
            backdropFilter: 'blur(10px)', // Glassy effect
            boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px`,
            color: theme.menuSelected,
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // Adjust padding here
          padding: 10, // Adjust as needed
          minHeight: 'auto', // Adjust to ensure icons are aligned correctly
          '& .MuiTab-iconWrapper': {
            // Adjust icon size and alignment if needed
            paddingRight: 1
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.divider}`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: `${theme.backgroundDefault}50`,
          borderRadius: `10px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.divider
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.primaryLight
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          }
        },
        input: {
          borderRadius: `10px`,
          background: `${theme.backgroundDefault}50`,
          padding: '15.5px 14px',
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
              background: `${theme.backgroundDefault}50`,
            }
          }
        },
        inputAdornedStart: {
          paddingLeft: 4,
          background: `${theme.backgroundDefault}50`,
        },
        notchedOutline: {
          borderRadius: `10px`
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300
          }
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px'
        },
        valueLabel: {
          color: theme?.colors?.primaryLight
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: theme.mode === 'dark'
            ? 'rgba(30, 30, 30, 0.7)' // Dark mode background for glass effect
            : 'rgba(255, 255, 255, 0.7)', // Light mode background for glass effect
          backdropFilter: 'blur(10px)', // Apply blur for the glass effect
          border: theme.mode === 'dark'
            ? '1px solid rgba(255, 255, 255, 0.1)' // Light border in dark mode
            : '1px solid rgba(0, 0, 0, 0.1)', // Dark border in light mode
          color: theme.mode === 'dark'
            ? '#ffffff' // Text color for dark mode
            : '#000000', // Text color for light mode

          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit', // Inherit color from the Chip
          },

          // You can add additional styles as needed
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
        }
      }
    }
  };
}
