import {
  dateToFirebaseTimestamp,
  firebaseTimestampToDate,
  registerDocumentListener,
  getFirebaseTimestampNow,
  getFirebaseDateNow,
} from 'database/firebase/api/database'

// Usage
export const getServerTimestampNow = async () => {
  return await getFirebaseTimestampNow()
}

export const getServerDateNow = async () => {
  return await getFirebaseDateNow()
}

export const dateToServerTimeStamp = (date) => {
  return dateToFirebaseTimestamp(date)
}

export const serverTimestampToDate = (timestamp) => {
  return firebaseTimestampToDate(timestamp);
};

export const attachSystemListener = async (callback) => {
  const path = 'info/data';
  return await registerDocumentListener(path, callback);
};