import React, { forwardRef } from 'react';
import { ErrorOutline, CheckCircleOutline } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { SnackbarContent } from 'notistack';


export const SuccessSnackbar = forwardRef((props, ref) => {

  const theme = useTheme();
  const { message } = props;

  return (
    <SnackbarContent ref={ref} role="alert" style={{
      borderRadius: '15px',
      backgroundColor: `${theme.palette.success.main}A0`,
      backdropFilter: 'blur(10px)', // add blur effect
      WebkitBackdropFilter: 'blur(10px)', // for Safari support
    }}>
      <Box sx={{ p: 2 }} display="flex" alignItems="center">
        <CheckCircleOutline style={{ marginRight: 8, color: 'white' }} />
        <Typography variant="body2" color="white">
          {message}
        </Typography>
      </Box>
    </SnackbarContent>
  );
});


export const ErrorSnackbar = forwardRef((props, ref) => {
  const { message } = props;
  const theme = useTheme();

  return (
    <SnackbarContent ref={ref} role="alert" style={{
      borderRadius: '15px',
      backgroundColor: `${theme.palette.error.main}A0`,
      backdropFilter: 'blur(10px)', // add blur effect
      WebkitBackdropFilter: 'blur(10px)', // for Safari support
    }}>
      <Box sx={{ p: 2 }} display="flex" alignItems="center">
        <ErrorOutline style={{ marginRight: 8, color: 'white' }} />
        <Typography variant="body2" color="white">
          {message}
        </Typography>
      </Box>
    </SnackbarContent>
  );
});

export const WarningSnackbar = forwardRef((props, ref) => {
  const { message } = props;
  const theme = useTheme();

  return (
    <SnackbarContent ref={ref} role="alert" style={{
      borderRadius: '15px',
      backgroundColor: `${theme.palette.warning.dark}A0`,
      backdropFilter: 'blur(10px)', // add blur effect
      WebkitBackdropFilter: 'blur(10px)', // for Safari support
    }}>
      <Box sx={{ p: 2 }} display="flex" alignItems="center">
        <ErrorOutline style={{ marginRight: 8, color: 'white' }} />
        <Typography variant="body2" color="white">
          {message}
        </Typography>
      </Box>
    </SnackbarContent>
  );
});
