import PropTypes from 'prop-types';

import React, { createContext, useState, useEffect } from 'react';
import { debounce } from 'lodash';

import { attachUsersListener } from 'database/repositories/UsersRepo';
import { attachProfileListener } from 'database/repositories/CustomersRepo'
import { attachSystemListener } from 'database/repositories/SystemRepo';
import { userSignOut, authStateChangedHandler } from 'database/firebase/api/auth';
import { attachProductsListener } from 'database/repositories/ProductsRepo';
import { attachAreasListener } from 'database/repositories/AreasRepo';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [globalProfile, setGlobalProfile] = useState(null);
  const [systemInfo, setsystemInfo] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [areasData, setAreasData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [systemSubscribe, setSystemSubscribe] = useState(null)
  const [profileSubscribe, setProfileSubscribe] = useState(null)
  const [productsSubscribe, setProductsSubscribe] = useState(null)
  const [areasSubscribe, setAreasSubscribe] = useState(null)
  const [usersSubscribe, setUsersSubscribe] = useState(null)

  const setSystem = (systemInfo) => {
    setsystemInfo(systemInfo);
  };

  const setProfile = (globalProfile) => {
    setGlobalProfile(globalProfile);
  };

  const setAreas = (data) => {
    if (data) {
      const updatedAreasMap = { ...areasData };
      data.forEach(updatedDoc => {
        updatedAreasMap[updatedDoc.id] = { ...updatedDoc };
      });
      setAreasData(updatedAreasMap);
    }
  };

  const setUsers = (data) => {
    if (data) {
      const updatedUsersMap = { ...usersData };
      data.forEach(updatedDoc => {
        updatedUsersMap[updatedDoc.id] = { ...updatedDoc };
      });
      setUsersData(updatedUsersMap);
    }
  };


  const setProducts = (data) => {
    if (data) {
      const updatedProductsMap = { ...productsData };
      data.forEach(updatedDoc => {
        updatedProductsMap[updatedDoc.id] = { ...updatedDoc };
      });
      setProductsData(updatedProductsMap);
    }
  };

  const logoutHandler = () => {
    try {
      systemSubscribe();
      profileSubscribe();
      productsSubscribe();
      areasSubscribe();
      usersSubscribe();

      setSystemSubscribe(null);
      setProfileSubscribe(null);
      setProductsSubscribe(null);
      setAreasSubscribe(null);
      setUsersSubscribe(null);

      setsystemInfo(null)
      setGlobalProfile(null)
      setProducts(null)
      setAreas(null);
      setUsers(null);

      userSignOut();
    }
    catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const unsubscribe = authStateChangedHandler((user) => {
      setAuthenticatedUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const debouncedFetchUserProfile = debounce(async () => {
      try {
        if (!authenticatedUser) {
          setLoading(false);
          return;
        }

        const systemUnsubscribe = await attachSystemListener(setSystem);
        const profileUnsubscribe = await attachProfileListener(authenticatedUser.uid, setProfile);
        const productsUnsubscribe = await attachProductsListener(setProducts);
        const areasUnsubscribe = await attachAreasListener(setAreas);
        const usersUnsubscribe = await attachUsersListener(setUsers);

        setSystemSubscribe(() => systemUnsubscribe);
        setProfileSubscribe(() => profileUnsubscribe);
        setProductsSubscribe(() => productsUnsubscribe);
        setAreasSubscribe(() => areasUnsubscribe);
        setUsersSubscribe(() => usersUnsubscribe);

      }
      catch (error) {
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    }, 1500);

    debouncedFetchUserProfile();

    return () => {
      debouncedFetchUserProfile.cancel();
    };
    // eslint-disable-next-line
  }, [loading, authenticatedUser]);

  return (
    <AuthContext.Provider
      value={{
        authenticatedUser,
        globalProfile,
        systemInfo,
        productsData,
        areasData,
        usersData,
        logout: logoutHandler,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
