import {
  firestoreSetObject,
  firestoreGetCollection,
  registerDocumentListener,
} from 'database/firebase/api/database'

import {
  firebaseStorageUploadFile,
} from 'database/firebase/api/storage'

export const attachProfileListener = async (authID, callback) => {
  try {
    const customer = await getCustomersByAuthID(authID);
    if (customer) {
      return await registerDocumentListener(`customers/${customer.id}`, callback);
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = (customer) => {
  return new Promise(async (resolve, reject) => {
    await firestoreSetObject(`customers/${customer.id}`, customer)
      .then((customer) => {
        console.log('updateCustomer success')
        resolve(customer);
      })
      .catch((error) => {
        console.log('updateCustomer Fail')
        reject(error);
      });
  });
}

export const getCustomersByAuthID = (authID) => {
  return new Promise((resolve, reject) => {
    const path = 'customers'
    const filters = [
      {
        'key': 'authID',
        'op': '==',
        'value': authID
      }
    ]
    const orders = []
    const limit = 0;

    firestoreGetCollection(path, filters, orders, limit)
      .then((customers) => {
        console.log('getCustomersByAuthID success')
        if (customers) {
          resolve(customers[0])
        }
        else {
          resolve(null)
        }
      })
      .catch((error) => {
        console.log('getCustomersByAuthID Fail')
        reject(error);
      });
  });
}


export const uploadCustomerProfilePicture = (customerID, file) => {
  return new Promise((resolve, reject) => {
    firebaseStorageUploadFile(`customers/${customerID}/`, 'profile_picture', file)
      .then((data) => {
        console.log('uploadCustomerProfilePicture success');
        resolve(data);
      })
      .catch((error) => {
        console.log('uploadCustomerProfilePicture Fail');
        reject(error);
      });
  });
};