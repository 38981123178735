import { lazy } from 'react';

// project imports
import Loadable from 'Loadable';

import { AuthGuard } from 'database/guards/AuthGuard';

const MainLayout = Loadable(lazy(() => import('panel/src/pages/MainLayout')));

const Home = Loadable(lazy(() => import('views/Home')));

const OrderManagement = Loadable(lazy(() => import('views/Home/Orders/OrderManagement')));

// ==============================|| MAIN ROUTING ||============================== //

const authenticatedRoutes = {
  path: '/',
  element: <AuthGuard />,
  children: [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'orders',
          element: <Home />
        },
        {
          path: 'about',
          element: <Home />
        },
        {
          path: 'orders/:orderID',
          element: <OrderManagement />
        },
        {
          path: 'orders/:orderID/status',
          element: <OrderManagement />
        },
        {
          path: 'orders/:orderID/maintenance',
          element: <OrderManagement />
        },
        {
          path: 'orders/:orderID/documents',
          element: <OrderManagement />
        },
      ]
    }
  ]
};

export default authenticatedRoutes;
